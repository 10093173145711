import React from "react";
import NavButton from "./NavButton"
import "./Navigator-style.css";
import { navButtons } from "./utils";
import { GiHamburgerMenu } from "react-icons/gi";
import SGLogoBanner from "./SGLogoBanner";
import { isMobile } from "../../utils/global-variables";
import {useTranslation} from "react-i18next"

export const Navigator = props => {
  const {t} = useTranslation()

  const navButtonsElem = navButtons.map((navButton, index) => {
    let navBtnClassName = ""
    if(index === 0 && isMobile) navBtnClassName += "nav-btn-padding-top"
    else if(index === navButtons.length - 1 && isMobile) navBtnClassName += "nav-btn-padding-bottom"

    if (navButton.children === "HOME" && !isMobile) return <SGLogoBanner key={index}/>;
    return (
      <NavButton
        className={navBtnClassName}
        id={navButton.id}
        dropdownOptions={navButton.dropdownOptions}
        key={index}
        to={navButton.to}
      >
        {t(navButton.children)}
      </NavButton>
    );
  });

  return (
    <>
      <div className="pc">
        <div className="row no-gutters">{navButtonsElem}</div>
      </div>

      <div className="mobile">
        <div className="row no-gutters">
          <SGLogoBanner />
          <div
            className="col nav-btn-mobile-col"
            data-toggle="collapse"
            data-target="#hamburgerCollapse"
            aria-expanded="false"
            aria-controls="hamburgerCollapse"
          >
            <GiHamburgerMenu className="hamburger" size={20} />
          </div>
        </div>
        <div className="collapse" id="hamburgerCollapse">
          {navButtonsElem}
        </div>
      </div>
    </>
  );
};
