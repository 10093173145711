import React from 'react'
import {SwitchLangBtn} from "../../component"
import "./SGMotto-style.css"
import {useTranslation} from "react-i18next"

export const SGMotto = props => {
    const {t} = useTranslation()

    return (
        <>
            <div className="pc">
                <div className="row no-gutters sg-motto">
                    <div className="col-10">
                        <span className="yellow-color sg-motto-text">{t('motto')}</span>
                    </div> 
                    <div className="col sg-motto-lang">
                        <SwitchLangBtn/>
                    </div> 
                </div>
            </div>

            <div className="mobile">
                <div className="row no-gutters sg-motto">
                    <div className="col-9">
                        <span className="yellow-color sg-motto-text">{t('mottoMobile')}</span>
                    </div>
                    <div className="col sg-motto-lang">
                        <SwitchLangBtn/>
                    </div> 
                </div>
            </div>
        </>
    )
}