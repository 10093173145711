export const langsFull = {
    "en" : "English",
    "th" : "ภาษาไทย"
}

export const getMobileLang = (lang) => lang.toUpperCase()
export const getPCLang = (lang) => langsFull[lang]

export const getLangFontClassName = (lang) => {
    switch(lang){
        case "th":
            return "thai-font"
        case "en":
        default:
            return "english-font"
    }
}