import React from "react";
import { SGPaddingCard, PCComp } from "../../component";
import { useTranslation } from "react-i18next";
import "./About-style.css";
import SGTeam from "./SGTeam";
import SGAboutTitle from "./SGAboutTitle";
import { teams } from "./utils";

export const About = props => {
  const { t } = useTranslation();
  const aboutUsElem = (
    <>
      <SGAboutTitle>{t("ABOUT US")}</SGAboutTitle>
      <p className="large-font">{t("aboutUsContent")}</p>
    </>
  );

  const ourGoalsElem = (
    <>
      <SGAboutTitle>{t("OUR GOALS")}</SGAboutTitle>
      <p className="large-font">{t("ourGoalsContent")}</p>
    </>
  );

  const ourTeamElem = (
    <SGTeam
      teams={teams}
      pcClassName="about-pc"
      mobileClassName="about-mobile"
    />
  );

  return (
    <>
      <PCComp pcClassName="about-pc">
        <SGPaddingCard>{aboutUsElem}</SGPaddingCard>
        <div className="content-s-offset" />
        <SGPaddingCard>{ourGoalsElem}</SGPaddingCard>
        <div className="content-offset" />
        {ourTeamElem}
      </PCComp>

      <div className="about-mobile gradient-bg about-mobile-padding">
        <div className="text-center-align pb-4">
          {aboutUsElem}
          {ourGoalsElem}
          {ourTeamElem}
        </div>
      </div>
    </>
  );
};
