import React from "react";
import "./SGFooter.js-style.css";
import { navs, contacts } from "./utils.js";
import { Link } from "react-router-dom";
import { FollowUs } from "../../component";
import { useTranslation } from "react-i18next";
import { isMobile } from "../../utils/global-variables";
import {SGLink} from "../../component"

const Copyright = (props) => {
  let getCurrentYear = props.lang && props.lang === 'th' ? new Date().getFullYear() + 543 : new Date().getFullYear()
  let className = "text-center-align copyright-mb yellow-color ";
  if (props.className) className += props.className;
  
  return (
    <div className={className}>{props.t('Copyright Start Year') + getCurrentYear + props.t('Copyright Company')}</div>
  );
};

export const SGFooter = props => {
  const { i18n, t } = useTranslation();

  const firstCol = navs.map((nav, index) => {
    return (
      <div key={index} className="sg-footer-first-col">
        <Link to={nav.to} className="sg-footer-content">
          {t(nav.children)}
        </Link>
      </div>
    );
  });

  const secondCol = contacts.map((contact, index) => {
    let contentElem = (
      <>
        {t(contact.topic)} {contact.content}
      </>
    );
    if (contact.topic === "LinkedIn")
      contentElem = (
        <>
          {t(contact.topic)}{" "}
          <SGLink href="https://www.linkedin.com/in/piyabhut-wongsahan-00a55413a/" underline>
            {contact.content}
          </SGLink>
        </>
      );

    if (isMobile)
      return (
        <div key={index} className="text-center-align">
          <div className="yellow-color">{contentElem}</div>
        </div>
      );
    return (
      <div key={index} className="yellow-color">
        {contentElem}
      </div>
    );
  });

  return (
    <>
      <div className="pc">
        <div className="sg-footer">
          <div className="row no-gutters">
            <div className="col sg-footer-col">
              <div className="sg-footer-right-border">
                {/* <div className="sg-footer-content-header">NAVIGATOR</div> */}
                {firstCol}
              </div>
            </div>
            <div className="col sg-footer-col">
              <div className="pb-4">
                <h5 className="yellow-color">{t("CONTACT US")}</h5>
                {secondCol}
              </div>
              <FollowUs />
            </div>
          </div>
          <Copyright lang={i18n.language} t={t}/>
        </div>
      </div>

      <div className="mobile">
        <div className="sg-footer">
          <div className="row no-gutters sg-footer-mobile-header">
            <h5 className="yellow-color">{t("CONTACT US")}</h5>
          </div>
          <div className="pb-2">{secondCol}</div>
          <div className="row no-gutters sg-footer-mobile text-center-align">
            <FollowUs />
          </div>
          <Copyright className="copyright-mb-mobile" lang={i18n.language} t={t}/>
        </div>
      </div>
    </>
  );
};
