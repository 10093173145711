import React from "react";
import "./PolygonSVG-style.css"
import {useLocation} from 'react-router-dom'

export const PolygonSVG = props => {
  let className = "sg-polygon-parent "
  const location = useLocation()

  switch(location.pathname){
    case "/projects":
      className += "projects-polygon-pc"
      break;
    default:
      break;
  }
 
  return (
    <div className={className}>
      <div className="sg-polygon"/>
    </div>
  );
};
