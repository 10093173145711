import React from "react";
import PropTypes from "prop-types";
import "./SGLink-style.css";
import { Link } from "react-router-dom";

export const SGLink = props => {
  let className = "sg-link ";

  if (props.underline) className += "underline ";
  if (props.className) className += props.className;

  const linkElem = props.to ? (
    <Link className={className} to={props.to}>
      {props.children}
    </Link>
  ) : (
    <span onClick={() => window.open(props.href)} className={className}>
      {props.children}
    </span>
  );
  
  return linkElem;
};

SGLink.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  href: PropTypes.string,
  to: PropTypes.string,
  underline: PropTypes.bool
};
