import React from 'react'
import PropTypes from 'prop-types'
import ArrowIcon from "./ArrowIcon"
import "./SGTitle-style.css"

export const SGTitle = props => {
    let className = "sg-title "
    let h1ClassName = "sg-title-h1 "
    
    if(props.className) className += props.className
    if(props.h1ClassName) h1ClassName += props.h1ClassName

    const leftArrowIcon = props.leftArrowTo ? <ArrowIcon direction="left" to={props.leftArrowTo}/> : null
    const rightArrowIcon = props.rightArrowTo ? <ArrowIcon direction="right" to={props.rightArrowTo}/> : null

    return (
        <div className={className}>
            {leftArrowIcon}
            <h1 className={h1ClassName}>{props.children}</h1>
            {rightArrowIcon}
        </div>
    )
}

SGTitle.propTypes = {
    leftArrowTo: PropTypes.string,
    rightArrowTo: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.string.isRequired,
}