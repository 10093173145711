import React from 'react'
import PropTypes from 'prop-types'
import {SGIcon} from "../../component"
import "./FollowUs-style.css"
import {useTranslation} from "react-i18next"
import { storePageUrl, twitterUrl, youtubeUrl, discordUrl } from "../../utils/global-variables"

const Icon = props => {
    return (
        <SGIcon className="ml-1 mr-1" {...props}/>
    )
}

export const FollowUs = props => {
    const {t} = useTranslation()
    
    let className = ""
    let firstIconClassName = "mr-1"

    if(props.className) className += props.className
    if(props.firstIconClassName) firstIconClassName = props.firstIconClassName

    return (
        <div className={className}>
            <h5 className="yellow-color pb-2">
                {t('FOLLOW US')}
            </h5>
            {/* <SGIcon className={firstIconClassName} src={require("../../assets/images/ig-icon.png")} onClick={() => window.open("https://www.w3schools.com")}/>
            <Icon src={require("../../assets/images/fb-icon.png")} onClick={() => window.open("https://www.w3schools.com")}/>
            <Icon src={require("../../assets/images/mail-icon.png")} onClick={() => window.open("https://www.w3schools.com")}/> */}
            <SGIcon onClick={() => window.open(storePageUrl + "footer")} className={firstIconClassName} src={require("../../assets/images/steam-icon.png")} />
            <Icon onClick={() => window.open(twitterUrl)} src={require("../../assets/images/twitter-icon.png")} />
            <Icon onClick={() => window.open(youtubeUrl)} src={require("../../assets/images/youtube-icon.png")} />
            <Icon onClick={() => window.open(discordUrl)} src={require("../../assets/images/discord-icon.png")} />
            {/* <Icon to="/comingsoon" src={require("../../assets/images/mail-icon.png")} /> */}
        </div>
    )
}

FollowUs.propTypes = {
    className: PropTypes.string,
    firstIconClassName: PropTypes.string,
}