import React from 'react'
import {SGIcon} from "../component"
import PropTypes from 'prop-types'

export const SGRightArrow = props => {
    return (
        <span className={props.className} href={props.href} data-slide="next">
            <SGIcon src={require("../assets/images/right-arrow.png")} {...props}/>
        </span>
    )
}

SGRightArrow.propTypes = {
    href: PropTypes.string,
    className: PropTypes.string,
}