import React from 'react'
import PropTypes from 'prop-types'
import {withRouter} from "react-router-dom"
import "./SGIcon-style.css"

const SGIconComp = props => {
    let className = "sg-icon "
    if(props.className) className += props.className

    const onClick = props.to ? () => props.history.push(props.to) : props.onClick

    return (
        <a href={props.href}>
            <img src={props.src} className={className} onClick={onClick} alt={!props.alt ? "..." : props.alt}/>
        </a>
    )
}

SGIconComp.propTypes = {
    src: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onClick: PropTypes.func,
    href: PropTypes.string,
    to: PropTypes.string,
    alt: PropTypes.string,
}

export const SGIcon = withRouter(SGIconComp)