import React from "react";
import PropTypes from "prop-types";
import "./SGModal-style.css";
import { iconSize } from "../../utils/global-variables";
import SGModalContent from "./SGModalContent";
import SGModalCarousel from "./SGModalCarousel";
import { AiFillCloseCircle } from "react-icons/ai";

export const SGModal = props => {
  let modalContentClass = "modal-content ";
  const isCarousel = props.carouselPics.length > 0;
  const closeIconClass = isCarousel ? "close-icon-carousel" : "close-icon";
  const contentElem = isCarousel ? (
    <SGModalCarousel
      dataTarget={props.dataTarget}
      carouselPics={props.carouselPics}
    />
  ) : (
    <SGModalContent>{props.children}</SGModalContent>
  );
  let modalDialogClass = "modal-dialog modal-dialog-centered ";

  !isCarousel
    ? (modalContentClass += "sg-modal-content")
    : (modalContentClass += "sg-modal-content-carousel");
  !isCarousel
    ? (modalDialogClass += "modal-lg")
    : (modalDialogClass += "modal-dialog-carousel");
  const modalBodyClass = !isCarousel ? "modal-body" : "modal-body-carousel";

  return (
    <div
      className="modal fade sg-modal"
      id={props.dataTarget}
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className={modalDialogClass}>
        <div className={modalContentClass}>
          <AiFillCloseCircle
            className={closeIconClass}
            size={iconSize}
            data-dismiss="modal"
            aria-label="Close"
          />
          <div className={modalBodyClass}>{contentElem}</div>
        </div>
      </div>
    </div>
  );
};

SGModal.propTypes = {
  children: PropTypes.any,
  dataTarget: PropTypes.string.isRequired,
  carouselPics: PropTypes.array
};
