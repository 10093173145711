import React from 'react'
import "./Header-style.css"
import { Navigator, SGMotto, SGCover, PolygonSVG } from "../../component";

export const Header = props => {
    return (
        <>
          <SGMotto />
          <SGCover />
          <PolygonSVG/>
          <Navigator />
        </>
    )
}