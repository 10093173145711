import React, {useEffect} from "react";
import "./SwitchLangBtn-style.css";
import {langsFull, getMobileLang, getPCLang, getLangFontClassName} from "./utils"
import { useTranslation } from 'react-i18next'
import {isMobile} from "../../utils/global-variables"
import {getKeyByValue} from "../../utils/object"
import {MdLanguage} from "react-icons/md"

export const SwitchLangBtn = props => {
  const {i18n} = useTranslation()
  const currLang = isMobile ? getMobileLang(i18n.language) : getPCLang(i18n.language)

  useEffect(() => {
    changeFont()
  }, [i18n.language])

  const changeFont = () => {
    const body = document.querySelector("body")
    body.className = getLangFontClassName(i18n.language)
  }

  const langOnClick = (lang) => () => {
    const langShort = getKeyByValue(langsFull, lang)
    i18n.changeLanguage(langShort)
  }

  const langItemElem = Object.values(langsFull).map((lang, index) => {
    return (
      <button key={index} className="dropdown-item switch-lang-dropdown-item" onClick={langOnClick(lang)}>
        <MdLanguage className="mr-2 mb-1"/>        
        {lang}
      </button>
    )
  })

  return (
    <div className="dropdown">
      <button
        className="switch-lang-btn"
        type="button"
        id="switchDropdown"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <MdLanguage className="mr-2 mb-1"/>
        {currLang ? currLang : 'English'}
      </button>
      <div className="dropdown-menu switch-lang-dropdown-menu dropdown-menu-right" aria-labelledby="switchDropdown">
        {langItemElem}
      </div>
    </div>
  );
};
