import React from "react";
import PropTypes from "prop-types";
import "./SGLabel-style.css";

export const SGLabel = props => {
  let className = "sg-label ";
  if (props.className) className += props.className;

  return <span className={className}>{props.children}</span>;
};

SGLabel.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string
};
