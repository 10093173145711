export const teams = [
    {
        pictureUrl: require("../../assets/images/t1-pic.png"),
        headerKey: "t1-header",
        contentKey: "t1-content"
    },
    {
        pictureUrl: require("../../assets/images/t2-pic.png"),
        headerKey: "t2-header",
        contentKey: "t2-content"
    },
]