import React from "react";
import {
  SGCard,
  SGTitle,
  SGLink,
  PCComp
} from "../../component";
import "./Donate-style.css";
import { isMobileForLink } from "../../utils/global-variables";
import { useTranslation } from "react-i18next";

export const Donate = props => {
  const { t } = useTranslation();
  const titleElem = <SGTitle className="pb-4">{t("DONATE US")}</SGTitle>;
  const patreonLink = isMobileForLink ? (
    <SGLink href="https://www.patreon.com/shamefulguys">
          https://www.patreon.com/
          shamefulguys
    </SGLink>
  ) : (
    <SGLink href="https://www.patreon.com/shamefulguys">
          https://www.patreon.com/shamefulguys
    </SGLink>
  )
  const contentElem = (
    <>
      <h4>{t("SUPPORT US ON PATREON")}</h4>
      <p>{t("D-p1")}</p>
      <p>{t("D-p2")}</p>
      <p>{t("D-p3")}</p>
      <p>{t("D-p4")}</p>
      <p>{patreonLink}</p>
      <p>{t("D-p5")}</p>
    </>
  );

  return (
    <>
      <PCComp pcClassName="donate-pc" childrenClassName="support-col mr-5 ml-5">
        <SGCard className="content-card-padding">
            <div className="row no-gutters">
            <div className="col">
              {titleElem}
              {contentElem}
            </div>
            <div className="col support-second-col mr-5 ml-5">
              <a href="https://www.patreon.com/shamefulguys">
                <img
                  className="support-img"
                  src={require("../../assets/images/patreon.png")}
                  alt="..."
                />
              </a>
            </div>
          </div>
        </SGCard>
      </PCComp>

      <div className="donate-tablet">
        <div className="text-center-align">
          <SGCard>
            {titleElem}
            <div className="support-img-tablet-div">
              <a href="https://www.patreon.com/shamefulguys">
                <img
                  className="support-img pb-4"
                  src={require("../../assets/images/patreon-circle.png")}
                  alt="..."
                />
              </a>
            </div>
            {contentElem}
          </SGCard>
        </div>
      </div>
    </>
  );
};
