import React from "react";
import PropTypes from "prop-types";
import "./SGModalContent-style.css";

const SGModalContent = props => {
  return <div className="sg-modal-content-text">{props.children}</div>;
};

SGModalContent.propTypes = {
  children: PropTypes.any.isRequired
};

export default SGModalContent;
