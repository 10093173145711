import React from "react";
import { PCComp } from "../../component";
import { useTranslation } from "react-i18next";
import "./ComingSoon-style.css"

export const ComingSoon = props => {
  const { t } = useTranslation();
  const comingSoonElem = (
    <h1 className="text-center-align yellow-color">{t('Coming Soon!')}</h1>
  );
  
  return (
    <>
      <PCComp normalBg>{comingSoonElem}</PCComp>
      <div className="mobile not-found-mobile">{comingSoonElem}</div>
    </>
  );
};
