import React from 'react'
import PropTypes from 'prop-types'
import "./SGButton-style.css"

export const SGButton = props => {
    let className = "btn sg-button ";

    if(props.className) className += props.className;

    return (
        <div className={props.containerClassName}>
            <button type="submit" className={className}>{props.children}</button>
        </div>
    )
}

SGButton.propTypes = {
    children: PropTypes.any.isRequired,
    containerClassName: PropTypes.string,
    className: PropTypes.string
}   