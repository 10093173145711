import React from "react";
import PropTypes from "prop-types";
import "./SGCircle-style.css";
import ReactTooltip from "react-tooltip";
import { SGSearchIcon } from "../../icons";

export const SGCircle = props => {
  const width = props.width ? props.width.toString() + "px" : "100px";
  const style = {
    width: width,
    height: width,
    lineHeight: width,
    borderRadius: width,
    color: "black",
    textAlign: "center",
    fontSize: props.width ? Number(props.width) / 4 : 25,
    cursor: props.cursorPointer ? "pointer" : "auto",
    backgroundColor: props.color
      ? props.color
      : props.levelNumType === "even"
      ? "rgb(250,247,192)"
      : "rgb(247, 211, 112)"
  };

  return (
    <div data-tip data-for={props.id} className={props.className}>
      {props.content && <SGSearchIcon className="sg-circle-search-icon" />}
      <div style={style}>{props.children}</div>
      {props.content ? (
        <>
          <ReactTooltip
            id={props.id}
            place="top"
            type="dark"
            effect="solid"
            backgroundColor="rgb(251,228,164)"
            textColor="black"
          >
            {props.content}
          </ReactTooltip>
        </>
      ) : null}
    </div>
  );
};

SGCircle.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  content: PropTypes.any,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.string,
  levelNumType: PropTypes.oneOf(["odd", "even"]),
  cursorPointer: PropTypes.bool
};
