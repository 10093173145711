import React from 'react'
import PropTypes from 'prop-types'
import {SGIcon} from "../../component"
import "./SGModalCarousel-style.css"

const SGModalCarousel = props => {
    const dataTarget = props.dataTarget + 'modalTarget'

    const carouselItemsElem = props.carouselPics.map((pic, index) => {
        let carouselItemClass = "carousel-item"
        if(index === 0) carouselItemClass += " active"

        return (
            <div key={index} className={carouselItemClass}>
                <img src={pic} className="d-block sg-modal-carousel-img" alt="..."/>
            </div>
        )
    })

    return (
        <div className="sg-modal-content-carousel">
            <div id={dataTarget} className="carousel" data-ride="carousel" data-interval="false">
                <div className="carousel-inner">
                  {carouselItemsElem}
                </div>

                <span href={`#${dataTarget}`} data-slide="prev" >
                    <SGIcon src={require("../../assets/images/left-arrow.png")} className="sg-modal-carousel-left-arrow"/>
                </span>
                <span href={`#${dataTarget}`} data-slide="next">
                    <SGIcon src={require("../../assets/images/right-arrow.png")} className="sg-modal-carousel-right-arrow"/>            
                </span>

          </div>
        </div>
    )
}

SGModalCarousel.propTypes = {
    dataTarget: PropTypes.string,
    carouselPics: PropTypes.array.isRequired,
}

export default SGModalCarousel;