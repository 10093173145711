import React from "react";
import { SGCard } from "../../component";
import SGAboutTitle from "./SGAboutTitle";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import "./About-style.css"

const SGTeam = props => {
  const { t } = useTranslation();
  const ourTeamElem = <SGAboutTitle>{t("OUR TEAM")}</SGAboutTitle>
  const teamPicElem = (pictureUrl) => <img className="about-team-pic" src={pictureUrl} alt="..."/>
  const teamContentElem = (team, index) => (
    <>
      <h4 className="yellow-color">{t(team.headerKey)}</h4>
      <div className={index === props.teams.length - 1 ? "" : "mb-4"}>{t(team.contentKey)}</div>
    </>
  )

  const teamsElem = props.teams.map((team, index) => {
    return (
      <div key={index} className="row team-row">
        <div className="col-3">
          {teamPicElem(team.pictureUrl)}
        </div>
        <div className="col">
          {teamContentElem(team, index)}
        </div>
      </div>
    );
  });

  const teamsMobileElem = props.teams.map((team, index) => {
    return (
      <span key={index}>
        <div className="mb-2">{teamPicElem(team.pictureUrl)}</div>
        <div>{teamContentElem(team, index)}</div>
      </span>
    )
  })

  return (
    <>
      <div className={props.pcClassName}>
        <SGCard className="content-card-padding">
          {ourTeamElem}
          {teamsElem}
        </SGCard>
      </div>
      <div className={props.mobileClassName}>
        {ourTeamElem}
        {teamsMobileElem}
      </div>
    </>
  );
};

SGTeam.propTypes = {
  teams: PropTypes.array.isRequired,
  pcClassName: PropTypes.string.isRequired,
  mobileClassName: PropTypes.string.isRequired,
};

export default SGTeam;
