import React from "react";
import { SGPaddingCard, SGTitle, PCComp } from "../../component";
import "./NotFound-style.css"

export const NotFound = props => {
  const notFoundElem = (
    <h1 className="text-center-align yellow-color">404 - NOT FOUND</h1>
  );
  
  return (
    <>
      <PCComp normalBg>{notFoundElem}</PCComp>
      <div className="mobile not-found-mobile">{notFoundElem}</div>
    </>
  );
};
