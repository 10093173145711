import React from "react";
import {
  SGCard,
  SGTitle,
  PCComp,
  SGLabel,
  SGIcon,
  SGCarousel,
  SGModal,
  PresskitBtn
} from "../../component";
import { storePageUrl, twitterUrl, youtubeUrl, discordUrl } from "../../utils/global-variables";
import { useTranslation } from "react-i18next";
import "./Projects-style.css";
import { SGHomeIcon, SGHomeIconDiv } from "../../page/Home/Home";
import SGLevel from "./SGLevel";
import { levelsCarousel, carouselPics } from "./utils";

const SGProjectsTitle = props => (
  <SGTitle className="pb-4">{props.children}</SGTitle>
);

const SGTitleMobile = props => (
  <SGTitle className="pt-3 pb-1">{props.children}</SGTitle>
);

export const Projects = props => {
  const { t } = useTranslation();

  const tsdContentElem = (
    <>
      <p>{t("tsd-p1")}</p>
      <p>{t("tsd-p2")}</p>
    </>
  );

  const tsdPic = (
    <img
      className="tsd-pic"
      src={require("../../assets/images/game-logo2.png")}
      alt="..."
    />
  );

  const screenshotsElem = (
    <div data-toggle="modal" data-target="#screenshotsModal">
      <h5 className="sg-link underline">{t("SCREENSHOTS")}</h5>
    </div>
  );

  const presskitElem = <div className="pb-2"><PresskitBtn className="large-font bold-font"/></div>

  return (
    <>
      <SGModal dataTarget="screenshotsModal" carouselPics={carouselPics} />
      <PCComp pcClassName="projects-pc">
        <SGCard className="content-card-padding">
          <div className="row no-gutters">
            <div className="col">
              <SGProjectsTitle>{t("ROADMAP")}</SGProjectsTitle>
              <p className="large-font pb-3">{t("roadmapContent")}</p>
              <SGLevel />
            </div>
            <div className="col"></div>
          </div>
        </SGCard>

        <div className="content-offset" />

        <SGCard className="content-card-padding">
          <SGProjectsTitle>{t("GAMES")}</SGProjectsTitle>
          <div className="row">
            <div className="col">
              <p className="large-font">{t("gamesDesc")}</p>
            </div>
            <div className="col" />
          </div>
          <div className="row">
            <div className="col tsd-pic-col">{tsdPic}</div>
            <div className="col">
              <SGProjectsTitle>Bangkok Story: A Stray Dog</SGProjectsTitle>
              {tsdContentElem}
              {/* <div className="dev-blog-offset" /> */}
              {presskitElem}
              <div className="pb-3">{screenshotsElem}</div>
              <SGHomeIconDiv>
                <SGLabel className="mr-3">{t("Development Blog")}</SGLabel>
                {/* <SGHomeIcon to="/comingsoon" src={require("../../assets/images/fb-icon.png")} /> */}
                <SGHomeIcon onClick={() => window.open(twitterUrl)}
                  src={require("../../assets/images/twitter-icon.png")}
                />
                <SGHomeIcon onClick={() => window.open(youtubeUrl)} src={require("../../assets/images/youtube-icon.png")} />
                <SGIcon onClick={() => window.open(discordUrl)} src={require("../../assets/images/discord-icon.png")} />
              </SGHomeIconDiv>
              <div>
                <div className="inline-flex">
                  <SGLabel className="mr-3">{t("Go to Store")}</SGLabel>
                  <SGIcon onClick={() => window.open(storePageUrl + "Project_page_PC")}
                    src={require("../../assets/images/steam-icon.png")}
                  />
                </div>
              </div>
            </div>
          </div>
        </SGCard>
      </PCComp>

      <div className="projects-tablet">
        <div className="text-center-align gradient-bg pl-4 pr-4">
          <SGTitleMobile className="pt-3 pb-1">{t("ROADMAP")}</SGTitleMobile>
          <SGCarousel
            className="pt-3 pb-3"
            id="levelCarousel"
            carouselItems={levelsCarousel}
          />
          <div className="large-font">{t("roadmapContent")}</div>

          <SGTitleMobile className="pt-3 pb-1">{t("GAMES")}</SGTitleMobile>
          <div className="large-font">{t("gamesDesc")}</div>
          <SGTitle className="pt-3 pb-2">Bangkok Story: A Stray Dog</SGTitle>

          {tsdPic}
          {tsdContentElem}
          {presskitElem}
          {screenshotsElem}

          <div className="pt-3 pb-5">
            <SGHomeIcon onClick={() => window.open(storePageUrl + "Project_page_mobile")}
              src={require("../../assets/images/steam-icon.png")}
            />
            <SGHomeIcon onClick={() => window.open(twitterUrl)} src={require("../../assets/images/twitter-icon.png")} />
            <SGHomeIcon onClick={() => window.open(youtubeUrl)} src={require("../../assets/images/youtube-icon.png")} />
            <SGIcon onClick={() => window.open(discordUrl)} src={require("../../assets/images/discord-icon.png")} />
          </div>
        </div>
      </div>
    </>
  );
};
