// const projectOpts = [
//   {
//     to: "/roadmap",
//     children: "Our roadmap"
//   },
//   {
//     to: "/games",
//     children: "Games"
//   },
//   {
//     to: "/comingsoon",
//     children: "Coming Soon",
//   }
// ]

// const aboutOpts = [
//   {
//     to: "/goals",
//     children: "Our Goals"
//   },
//   {
//     to: "/team",
//     children: "Our Team"
//   },
//   {
//     to: "/contact",
//     children: "Contact Us"
//   }
// ]

export const navButtons = [
  {
    to: "/",
    children: "HOME"
  },
  {
    to: "/projects",
    children: "PROJECTS"
  },
  {
    to: "/about",
    children: "ABOUT US"
  },
  // {
  //   id: "projectDropdown",
  //   children: "PROJECTS",
  //   dropdownOptions: projectOpts
  // },
  // {
  //   id: "aboutDropdown",
  //   children: "ABOUT US",
  //   dropdownOptions: aboutOpts
  // },
  {
    to: "/donate",
    // to: "/comingsoon",
    children: "❤️️ DONATE US"
  }
];

export const navButtonsIndex = {
  "/": 0,
  "/roadmap": 1,
  "/games" : 1,
  "/comingsoon" : 1,
  "/goals" : 2,
  "/team" : 2,
  "/contact" : 2,
  "/donate": 3
}