import React from "react";
import PropTypes from "prop-types";
import { LinearGradientSVG } from "../../component";
import "./PCComp-style.css";

export const PCComp = props => {
  let childrenColClassName = "col to-right-gradient-bg ";
  if (props.normalBg) childrenColClassName = "col ";
  if (props.childrenColClassName)
    childrenColClassName += props.childrenColClassName;

  return (
    <>
      <div className={props.pcClassName}>
        <div className={props.offsetClassName} />
        <div className="row no-gutters">
          <div className="col-2 linear-col">
            <LinearGradientSVG />
          </div>
          <div className={childrenColClassName}>
            {props.children}
            {!props.noBottomOffset && <div className="pc-comp-bottom-offset" />}
          </div>
        </div>
      </div>
    </>
  );
};

PCComp.propTypes = {
  pcClassName: PropTypes.string,
  childrenColClassName: PropTypes.string,
  offsetClassName: PropTypes.string,
  children: PropTypes.any,
  normalBg: PropTypes.bool,
  noBottomOffset: PropTypes.bool
};

PCComp.defaultProps = {
  pcClassName: "pc",
  offsetClassName: "pc-comp-offset"
};
