import React from 'react'
import {SGIcon} from "../component"
import PropTypes from 'prop-types'

export const SGLeftArrow = props => {
    return (
        <span className={props.className} href={props.href} data-slide="prev">
            <SGIcon src={require("../assets/images/left-arrow.png")} {...props}/>
        </span>
    )
}

SGLeftArrow.propTypes = {
    href: PropTypes.string,
    className: PropTypes.string,
}