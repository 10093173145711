import React, { Suspense } from "react";
import ReactGA from 'react-ga';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import "./i18n";
import { Home, Donate, Projects, About, NotFound, ComingSoon } from "./page";
import { Header, SGFooter } from "./component";
import {unregister} from './serviceWorker';
import RouteChangeTracker from "./component/RouteChangeTracker/RouteChangeTracker"

const TRACKING_ID = "UA-207283532-1"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
// unregister();

function App() {
  return (
    <Suspense fallback=" ">
      <Router>
        <RouteChangeTracker/>
        <Header />
        <div className="content-container">
          <Switch>
            <Route exact path="/" component={Home}/>
            <Route path="/projects" component={Projects}/>
            <Route path="/about" component={About}/>
            <Route path="/donate" component={Donate}/>
            <Route path="/comingsoon" component={ComingSoon}/>
            <Route path="/press" component={() => {
              window.location.href = '../press'; 
              return null;
            }}/>
            <Route component={NotFound}/>
          </Switch>
        </div>
        <SGFooter />
      </Router>
    </Suspense>
  );
}

export default App;
