import React from "react";
import { SGCircle } from "../../component";
import { levels } from "./utils";
import "./SGLevel-style.css";
import SGCircleLevel from "./SGCircleLevel"

const SGArrow = props => <div className="arrow-right" />;
const SGLine = props => <div className="sg-line" />;

const SGLevel = props => {
  const levelsElem = levels.map((level, index) => {
    const levelNum = index + 1;

    return (
      <span className="sg-level-div" key={index}>
        <SGCircleLevel
          id={level.id}
          content={level.content}
          levelNum={levelNum}
        >
          {level.children}
        </SGCircleLevel>
        <SGLine />
      </span>
    );
  });

  return (
    <div className="sg-level-div">
      <SGCircle width={35} color="rgb(238,168,67)" />
      <div className="mr-2" />
      <SGCircle width={60} />
      <SGLine />
      {levelsElem}
      <SGArrow/>
    </div>
  );
};

export default SGLevel;
