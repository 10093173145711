import React from "react";
import "./SGLogoBanner-style.css";
import { Link } from "react-router-dom";
import {isMobile} from "../../utils/global-variables"

const SGLogoBanner = props => {

  let linkClass = "sg-logo-col "

  if(isMobile) linkClass += "col-9"
  else linkClass += "col"

  return (
    <Link className={linkClass} to="/">
      <img
        className="sg-logo1"
        src={require("../../assets/images/shamefulguy-logo1.png")}
        alt="..."
      />
      <img
        className="sg-logo2"
        src={require("../../assets/images/shamefulguy-logo2.png")}
        alt="..."
      />
    </Link>
  );
};

export default SGLogoBanner;
