import React from 'react'
import {SGCircle} from "../../component"
import PropTypes from 'prop-types'
import {getNumType} from "./utils"

const SGCircleLevel = props => {
    return (
        <SGCircle
          id={props.id}
          className={props.className}
          width={130}
          cursorPointer
          levelNumType={getNumType(props.levelNum)}
          content={props.content}
        >
          {props.children}
        </SGCircle>
    )
}

export default SGCircleLevel;

SGCircleLevel.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    content: PropTypes.any,
    levelNum: PropTypes.number,
    children: PropTypes.string,
}