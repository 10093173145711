import { lorem } from "../../utils/global-variables";

export const gamesContent = "We believe that games have the most unique way to tell a story, the player can become and control the main character."
export const aboutUsContent =
  "Founded in August 2020 by a Thai former web/mobile developer who crazed about improving people's lives through storytelling using mediums like games, animations, etc. Quit his job, and pursue his passion full-time.";


export const carouselItems = [
  {
    subtitle: "GAMES",
    content: gamesContent,
    pictureUrl: require("../../assets/images/joystick.png")
  },
  {
    subtitle: "ANIMATION",
    content: lorem,
    pictureUrl: require("../../assets/images/game-pic1.png")
  }
];

const devIcons = [
  {
    iconSrc: require("../../assets/images/fb-icon.png"),
    onClick: () => alert("link to steam development blog")
  },
  {
    iconSrc: require("../../assets/images/ig-icon.png"),
    onClick: () => alert("link to steam development blog 2")
  }
];

const storeIcons = [
  {
    iconSrc: require("../../assets/images/steam-icon.png"),
    onClick: () => alert("link to steam")
  },
  {
    iconSrc: require("../../assets/images/steam-icon.png"),
    onClick: () => alert("link to steam 2")
  }
];

export const sgPicCardBtns = [
  {
    label: "Development Blog",
    icons: devIcons
  },
  {
    label: "Go to Store",
    icons: storeIcons
  }
];

export const carouselPics = [
  require("../../assets/images/game-pic1.png"),
  require("../../assets/images/game-pic2.png"),
  require("../../assets/images/game-pic3.png"),
  require("../../assets/images/game-pic4.png"),
  require("../../assets/images/game-pic5.png"),
];