export const navs = [
  {
    to: "/",
    children: "Home"
  },
  {
    to: "/projects",
    children: "Projects"
  },
  {
    to: "/about",
    children: "About Us"
  },
  {
    to: "/donate",
    children: "❤️️ Donate Us"
  }
];

export const contacts = [
  {
    topic: "E-mail",
    content: "shamefulguys@gmail.com",
  },
  // {
  //   topic: "Phone",
  //   content: "(+66) 81-510-1305",
  // },
  {
    topic: "LinkedIn",
    content: "Piyabhut Wongsahan",
  }
]