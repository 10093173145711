import React from "react";
import { useTranslation } from "react-i18next";
import SGCircleLevel from "./SGCircleLevel";
import "./Projects-style.css";

const TooltipElem = props => {
  const { t } = useTranslation();

  return (
    <>
      <h5>{props.header}</h5>
      <span>{t(props.content)}</span>
    </>
  );
};

export const levelsCarousel = [
  <SGCircleLevel
    className="center-align"
    id="lv1"
    content={
      <TooltipElem
        header="Lv. 1: The Empathy Tackler"
        content="lv1Content"
      />
    }
  >
    LV. 1
  </SGCircleLevel>,
  <SGCircleLevel
    className="center-align"
    id="lv2"
    content={
      <TooltipElem header="Lv. 2: The Sympathy Tackler" content="lv2Content" />
    }
  >
    LV. 2
  </SGCircleLevel>,
  <SGCircleLevel
    className="center-align"
    id="lv10"
    content={
      <TooltipElem header="Lv. 10 The Messenger" content="lv10Content" />
    }
  >
    LV. 10
  </SGCircleLevel>
];

export const levels = [
  {
    id: "lv1",
    children: "LV. 1",
    content: (
      <TooltipElem
        header="Lv. 1: The Empathy Tackler"
        content="lv1Content"
      />
    )
  },
  {
    id: "lv2",
    children: "LV. 2",
    content: (
      <TooltipElem header="Lv. 2: The Sympathy Tackler" content="lv2Content" />
    )
  },
  {
    id: "lv10",
    children: "LV. 10",
    content: <TooltipElem header="Lv. 10 The Messenger" content="lv10Content" />
  }
];

export const getNumType = number => (number % 2 === 0 ? "even" : "odd");

export const carouselPics = [
  require("../../assets/images/game-pic1.png"),
  require("../../assets/images/game-pic2.png"),
  require("../../assets/images/game-pic3.png"),
  require("../../assets/images/game-pic4.png"),
  require("../../assets/images/game-pic5.png"),
];