import React from "react";
import "./NavButton-style.css";
import PropTypes from "prop-types";
// import { navButtonsIndex } from "./utils";
import { Link } from "react-router-dom";

const NavButton = props => {
  let className = "nav-btn "
  if(props.className) className += props.className

  return (
    <>
      <Link className={className} to={props.to}>
          <span className="nav-btn-text">{props.children}</span>
        </Link>
    </>
  )
};

NavButton.propTypes = {
  id: PropTypes.string,
  children: PropTypes.any.isRequired,
  to: PropTypes.string,
  dropdownOption: PropTypes.array,
};

export default NavButton;
