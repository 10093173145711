import React from 'react'
import PropTypes from 'prop-types'
import "./SGCarousel-style.css"
import {SGLeftArrow, SGRightArrow} from "../../icons"

export const SGCarousel = props => {
    let className = "carousel slide "
    if(props.className) className += props.className

    const {id, carouselItems} = props
    const itemsElem = carouselItems.map((item, index) => {
        let itemClassName = "carousel-item "
        if(index === 0) itemClassName += "active"
        return (
            <div key={index} className={itemClassName}>
                {item}
            </div>
        )
    })

    return (
        <div id={id} className={className} data-ride="carousel" data-interval="false">
            <div className="carousel-inner">
                {itemsElem}
            </div>
            <SGLeftArrow className="sg-carousel-left-arrow" href={`#${id}`}/>
            <SGRightArrow className="sg-carousel-right-arrow" href={`#${id}`}/>
        </div>
    )
}

SGCarousel.propTypes = {
    id: PropTypes.string.isRequired,
    className: PropTypes.string,
    carouselItems: PropTypes.array.isRequired,
}