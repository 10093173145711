import React, { useState } from "react";
import {
  SGIcon,
  SGTitle,
  SGPicCard,
  SGCardCarousel,
  SGCard,
  SGModal,
  SGCircle,
  PolygonSVG,
  PCComp,
  SGLink,
  SGLabel,
  SGButton,
  PresskitBtn,
  FollowUs
} from "../../component";
import { carouselItems, sgPicCardBtns } from "./utils";
import {SGSearchIcon} from "../../icons"
import { storePageUrl, twitterUrl, youtubeUrl, discordUrl } from "../../utils/global-variables";
import "./Home-style.css";
import { useTranslation } from "react-i18next";
import axios from "axios";

export const SGHomeIcon = props => <SGIcon className="mr-3" {...props} />;
export const SGHomeIconDiv = props => (
  <div className="pb-3 inline-flex">{props.children}</div>
);

export const Home = props => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [subSuccess, setSubSuccess] = useState(false);
  const [errObj, setErrObj] = useState({});

  const submitEmail = async (e) => {
    e.preventDefault();
    try{
      // const url = `http://localhost:${process.env.REACT_APP_API_PORT}/.netlify/functions/api/subscribe/`;
      const url = `${process.env.REACT_APP_API_URL}/.netlify/functions/api/subscribe/`;
      const res = await axios.post(url, { email: email });
      if(res.status === 200){
        setSubSuccess(true);
        if(errObj.data) setErrObj({});
      }
    }
    catch(e){
      setErrObj(e.response);
      if(subSuccess) setSubSuccess(false);
    }
  }

  const ctaIconElem =  (device) => (
    <>
      <SGHomeIcon onClick={() => window.open(storePageUrl + "Home_page_" + device)} src={require("../../assets/images/steam-icon.png")} />
      {/* <SGHomeIcon src={require("../../assets/images/fb-icon.png")} /> */}
      <SGHomeIcon onClick={() => window.open(twitterUrl)} src={require("../../assets/images/twitter-icon.png")} />
      <SGHomeIcon onClick={() => window.open(youtubeUrl)} src={require("../../assets/images/youtube-icon.png")}/>
      <SGIcon onClick={() => window.open(discordUrl)} src={require("../../assets/images/discord-icon.png")}/>
    </>
  );

  const searchIconElem = <SGSearchIcon to="/projects" />
  const presskitLink = <SGLink to="/presskit" className="underline">PRESSKIT</SGLink>              
  const subscribeInputElem = <>
    <input 
      required 
      type="email"
      value={email}
      className={errObj.data ? 'form-control is-invalid' : 'form-control'}
      placeholder="E-mail" 
      aria-label="Username" 
      aria-describedby="basic-addon1"
      onChange={(e) => setEmail(e.target.value)}
    />
    <div className={subSuccess ? "sg-valid-feedback" : "display-none"}>{t('subsribeSuccessText')}</div>
    <div className={errObj.data ? "sg-invalid-feedback" : "display-none"}>{errObj.data}</div>
  </>
  const subscribeBtnElem = <SGButton containerClassName="center-align">{t("subscribeButton")}</SGButton>

  const homeContent = (
    <>
      <div className="game-bg">
        <img
          className="game-logo"
          src={require("../../assets/images/game-logo.png")}
          alt="..."
        />
      </div>

      <SGCard className="home-pc-sg-card">
        <div className="row first-row-pb">
          <div className="col-md-4">
            <img
              className="home-tsd-pic"
              src={require("../../assets/images/game-pic1.png")}
              alt="..."
            />
          </div>
          <div className="col home-game-content">
            <h2 className="yellow-color home-game-title">{t("gameTitle")}</h2>
            <div className="large-font pb-3">
              {t("gameContent")}
            </div>

            <div className="pc">
              <SGHomeIconDiv>
                <SGLabel className="mr-3">{t("FOLLOW US")}</SGLabel>
                {ctaIconElem("PC")}
              </SGHomeIconDiv>

              <div className="row">
                <div className="presskit-col large-font">
                  <PresskitBtn/>
                </div>

                <div className="col">
                  {searchIconElem}   
                </div>
              </div>
            </div>

            <div className="mobile-content">
              <div className="row">
                <div className="col pb-3">
                  {ctaIconElem("mobile")}
                </div>
              </div>
              
              <div className="row pb-3">
                <div className="col">
                  <PresskitBtn/>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  {searchIconElem}   
                </div>
              </div>
            </div>  
            
          </div>
        </div>

        <div className="home-game-content input-container">
          <h2 className="yellow-color center-align mb-3">{t("subscribeNewsletter")}</h2>

          <div className="pc">
            <form onSubmit={submitEmail}>
              <div className="form-group">

                <div className="row center-align">
                  <div className="col-7 sub-input-col">
                    {subscribeInputElem}
                  </div>
                  <div className="col-1">
                    {subscribeBtnElem}
                  </div>
                </div>

              </div>
            </form>
          </div>

          <div className="mobile-content">
            <form onSubmit={submitEmail}>
              <div className="row center-align input-row mb-3">
                {subscribeInputElem}
              </div>
              <div className="row center-align">
                {subscribeBtnElem}
              </div>
            </form>
          </div>

        </div>
      </SGCard>
    </>
  );

  return (
    <>
      <PCComp offsetClassName="home-offset" noBottomOffset>
        {homeContent}
      </PCComp>

      <div className="mobile">{homeContent}</div>

      {/* <SGCircle width={100} levelNumType="even"/> */}
      {/* <SGCircle width={100} color="red"/> */}
      {/* <SGCardCarousel
                title="OUR PROJECT"
                carouselItems={carouselItems}
            /> */}

      {/* <SGPicCard
        title="Bangkok Story: A Stray Dog"
        sgPicCardBtns={sgPicCardBtns}
        pictureUrl={require("../../assets/images/game-pic1.png")}
        picDirection="right"
        dataTarget="exampleModal"
      >
        This is a wider card with supporting text below as a natural lead-in to
        additional content. This content is a little bit longer.
      </SGPicCard> */}

      {/* <SGModal dataTarget="exampleModal">
        <center><h2>Level</h2></center>
        {longLorem}
      </SGModal> */}

      {/* <SGModal dataTarget="exampleModal" carouselPics={carouselPics}/> */}

      {/* <SGCard>
        <SGTitle rightArrowTo="/about">OUR GOALS</SGTitle>
        <p>
          {longLorem}
        </p>
        <p>{longLorem}</p>
      </SGCard> */}

      {/* <SGTitle leftArrowTo="/about" rightArrowTo="/about">OUR TEAM</SGTitle> */}
      {/* <SGIcon src={require("../../assets/images/left-arrow.png")} onClick={() => alert("test")} alt="left arrow"/> */}
    </>
  );
};
