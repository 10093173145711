import React from 'react'
import PropTypes from 'prop-types'
import "./SGCard-style.css"

export const SGCard = props => {
    let className = "sg-card to-right-gradient-bg "

    if(props.largeFontContent) className += "large-font"
    if(props.className) className += props.className

    return (
        <div className={className}>
            {props.children}
        </div>
    )
}

SGCard.propTypes = {
    children: PropTypes.any.isRequired,
    className: PropTypes.string,
    largeFontContent: PropTypes.bool
}   